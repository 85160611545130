import { isIE9 } from 'pnp_core_bundle/plugnplay/browser';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';

let parameters = {
    disruptions: {},
    disruptionsMessage: '',
    id: '',
    closePopupId: '#kisio-popup-close',
    popupClass: '.kisio-global-popup-full',
    multiple: false
};

class DisruptionsModel {
    init(options) {
        parameters = _.extend({}, parameters, options);
    };

    renderIcon(options) {
        let _this = this;
        _this.init(options);
        let severity = _this.getSeverity();
        return _this.htmlIcon(severity);
    };

    renderLineDisruption(options) {
        let _this = this;
        _this.init(options);
        let severity = _this.getSeverity();
        return _this.htmlLine(severity, options.multiple);
    };

    getSeverity() {
        let disruptionsArray = [], disruptionsId = [];
        for (const element of parameters.disruptions) {
            let severity = element.severity.level;
            let id = element.id;
            disruptionsArray.push(severity);
            disruptionsId.push(id);
        }
        if (disruptionsArray.indexOf('disrupt') !== -1) {
            parameters.id = disruptionsId[disruptionsArray.indexOf('disrupt')];
            return 'disrupt';
        } else if (disruptionsArray.indexOf('warning') !== -1) {
            parameters.id = disruptionsId[disruptionsArray.indexOf('warning')];
            return 'warning';
        } else if (disruptionsArray.indexOf('information') !== -1) {
            parameters.id = disruptionsId[disruptionsArray.indexOf('information')];
            return 'information';
        } else {
            return null;
        }
    };

    htmlIcon(severity) {
        let alt = Translator.trans('schedule.result.disruptions.stop_area.' + severity);
        return '<img src="/bundles/canaltppnpcore/images/disruptions/' + severity + '.svg" alt="' + alt + '" class="schedule-disruption" id="' + parameters.id + '"/>';
    };

    htmlLine(severity, multiple) {
        let div = JKisio('<div class="disruption-line ' + severity + '"/>');
        div.append(this.htmlIcon(severity));
        let text;
        if (multiple) {
            text = Translator.trans('schedule.result.disruptions.multiple');
        }else{
            text = Translator.trans('schedule.result.disruptions.line.' + severity);
        }
        div.append('<span aria-label="'+Translator.trans('schedule.result.disruptions.all')+'">' + text + '</span>');
        div.append('<i class="ikisio ikisio-arrow-drop-up"/>');

        return div;
    };

    showPopup(disruptions) {
        let _this = this;
        let disruptionsInfo = '';

        if (JKisio('.kisio-global-popup-full').length === 0) {
            let popup_full = JKisio('<div/>').addClass('kisio-global-popup-full');
            let global_popup = JKisio('<div/>').addClass('kisio-global-popup');
            let popup_header = JKisio('<div/>').addClass('kisio-popup-header');
            let close_icon = JKisio('<i/>').addClass('ikisio ikisio-close').attr('id', 'kisio-popup-close');
            let title;
            if (typeof JKisio('#stop_area_label').html() !== 'undefined') {
                title = '<div class="title">' + JKisio('#stop_area_label').html() + '</div>';
            }else{
                title = '<div class="title">' + JKisio('.title').html() + '</div>';
            }
            let network;
            if (typeof JKisio('.autocomplete-network-line').html() !== 'undefined') {
                network = '<div class="schedule-line-network">' + JKisio('.autocomplete-network-line').html() + '</div>';
            }else{
                network = '<div class="schedule-line-network">' + JKisio('.schedule-line-network').html() + '</div>';
            }
            let divLineInfo = JKisio('<div/>').addClass('disruption-line-info').append('<div class="disruption-line-info">'+title+network+'</div>');
            popup_header.append(divLineInfo);
            popup_header.append(close_icon);
            let popup_body = JKisio('<div/>').addClass('kisio-popup-body');
            let popup_footer = JKisio('<div/>').addClass('kisio-popup-footer');
            disruptions.forEach(function (disruption) {
                if (disruption.status === 'active') {
                    for (const element of disruption.messages) {
                        if (element.channel.name.match(Kisio.disruption_filter)) {
                            let disruptionType = disruption.impacted_objects[0].pt_object.embedded_type;
                            let impacted_objects = disruption.impacted_objects;
                            let html = '';
                            const disruptionsLines = [];
                            impacted_objects.forEach(function (object) {
                                let type = object.pt_object.embedded_type;
                                if (type !== 'network' && type !== 'stop_area') {
                                    let color = object.pt_object[type].text_color;
                                    let bgcolor = object.pt_object[type].color;
                                    let name = object.pt_object[type].code;
                                    if (typeof name !== "undefined" && !disruptionsLines.includes(name)) {
                                        if (color === '' || typeof color === "undefined") {
                                            color = '000';
                                        }
                                        if (bgcolor === '' || bgcolor === color || typeof bgcolor === 'undefined') {
                                            bgcolor = 'FFFFFF';
                                        }
                                        let border = '';
                                        if (bgcolor === 'FFFFFF') {
                                            border = "border: solid 1px #" + color + ';';
                                        }
                                        html += '<span style="background: #' + bgcolor + ' ; color:#' + color + ';' + border + '" class="lines">' + name + '</span>';
                                        disruptionsLines.push(name);
                                    }
                                }
                            });
                            let severity = disruption.severity.level;
                            let title = Translator.trans('schedule.result.disruptions.' + disruptionType + '.' + severity);
                            let disruptionHeader = JKisio('<div/>').addClass('disruption-header ' + severity);
                            let texte = element.text;

                            disruptionsInfo = JKisio('<div/>')
                                .addClass('disruption-message')
                                .append(texte);
                            disruptionHeader
                                .append(_this.htmlIcon(severity))
                                .append(html)
                                .append(title);
                            let disruptionBody = JKisio('<div/>')
                                .addClass('disruption-body')
                                .attr('tabindex', 0)
                                .append(disruptionHeader)
                                .append(disruptionsInfo);
                            popup_body
                                .append(disruptionBody);
                        }
                    }
                }
            });
            global_popup.append(popup_header)
                .append(popup_body)
                .append(popup_footer);
                popup_full.append(global_popup);
                popup_full.appendTo('body');

            // srollBar
            let popupHeaderHeight = JKisio('.kisio-popup-header').outerHeight(true);
            let popupGlobalHeight = JKisio('.kisio-global-popup').outerHeight(true);
            let popupBodyHeight = JKisio('.kisio-popup-body').outerHeight(true);
            if (popupBodyHeight > (popupGlobalHeight - popupHeaderHeight)) {
                JKisio('.kisio-popup-body').height(popupGlobalHeight - popupHeaderHeight - 50);
                if (!isIE9) {
                    try {
                        new SimpleBar(JKisio('.kisio-popup-body')[0]);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
            // close popup
            _this.hideOnClickOutside();
            JKisio(parameters.closePopupId).click(function (e) {
                JKisio(parameters.popupClass).remove();
            });
        }
    };

    hideOnClickOutside() {
        JKisio(document).click(function (e) {
            const targetClass = JKisio(e.target).attr('class');
            if (targetClass && targetClass === 'kisio-global-popup-full' ) JKisio(e.target).remove();
        })
    };
};

export default DisruptionsModel;
